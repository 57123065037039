
import { mapGetters } from "vuex";
import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

const reviewedPages = [
  "escorts-updates",
  "escorts-updates-slug",
  "categories-gender",
];

export default {
  name: "Default",

  components: {
    HeaderV2,
  },

  middleware: ["isActive"],

  // data() {
  //   return {
  //     headerHeight: null,
  //   };
  // },

  computed: {
    ...mapGetters({
      headerHidden: "global/getPaddingForHiddenHeader",
    }),

    renderInsideApp() {
      return reviewedPages.includes(this.$route.name);
    },

    // headerCSSVars() {
    //   return {
    //     "--header-height": `${this.headerHeight}px`,
    //   };
    // },
  },

  // watch: {
  //   $route() {
  //     this.setPagePadding();
  //   },
  // },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
    // this.$root.$on("set-page-padding", () => {
    //   this.setPagePadding();
    // });
  },

  // methods: {
  //   setPagePadding() {
  //     if (!this.$refs.header) return;
  //     this.headerHeight =
  //       this.$refs.header.$el.querySelector("header").clientHeight;
  //   },
  // },
};
