export default function ({ route, redirect, store }) {
  if (
    store.$auth.loggedIn &&
    store.$auth.user.user_role != 1 &&
    route.path !== "/dashboard/settings" &&
    route.path !== "/dashboard/member/settings"
  ) {
    const user = store.$auth.user;
    // add name after api update
    if (!user.email || !user.photo) {
      if (user.user_role == 2) {
        store.$notify({
          message: "Please enter your name and upload a profile photo",
          type: "warning",
        });
        return redirect("/dashboard/settings");
      } else if (user.user_role == 3) {
        store.$notify({
          message: "Please enter your name and upload a profile photo",
          type: "warning",
        });
        return redirect("/dashboard/member/settings");
      }
      return;
    }
  }
  return;
}
