import Vue from "vue";
import {
  getCroppedImageUrl,
  underscoreToSpaces,
  capitalize,
  lowercase,
} from "~/common/helpers";

export default ({ app }) => {
  /**
   *Converts a date to formatted string
   *
   * @param {*} date {Date, String, Number} - Date to be formatted
   * @param {string} [options={
   *       hour12: false,
   *       year: "numeric",
   *       month: "short",
   *       day: "numeric",
   *       hour: "numeric",
   *       minute: "numeric",
   *       second: "numeric",
   *     }] {Object} - Options for Intl.DateTimeFormat
   * @return {string} - Formatted date
   *
   * @example
   * {{ '2020-01-01T00:00:00.000Z' | formattedDate }} // Jan, 1 2020, 00:00:00
   */
  function formattedDate(
    date,
    options = {
      hour12: false,
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }
  ) {
    if (!date) return "";
    if (typeof date === "string") date = new Date(date);
    if (typeof date === "number") date = new Date(date);
    if (!(date instanceof Date)) return "";
    return new Intl.DateTimeFormat(app.i18n.locale, options).format(date);
  }

  Vue.filter("formattedDate", formattedDate);
  Vue.filter("getCroppedImageUrl", getCroppedImageUrl);
  Vue.filter("underscoreToSpaces", underscoreToSpaces);
  Vue.filter("capitalize", capitalize);
  Vue.filter("lowercase", lowercase);
};
