const middleware = {}

middleware['advertiser-or-manager'] = require('../middleware/advertiser-or-manager.js')
middleware['advertiser-or-manager'] = middleware['advertiser-or-manager'].default || middleware['advertiser-or-manager']

middleware['advertiser'] = require('../middleware/advertiser.js')
middleware['advertiser'] = middleware['advertiser'].default || middleware['advertiser']

middleware['advertiserAndManager'] = require('../middleware/advertiserAndManager.js')
middleware['advertiserAndManager'] = middleware['advertiserAndManager'].default || middleware['advertiserAndManager']

middleware['catalog-escort'] = require('../middleware/catalog-escort.js')
middleware['catalog-escort'] = middleware['catalog-escort'].default || middleware['catalog-escort']

middleware['catalog-routes'] = require('../middleware/catalog-routes.js')
middleware['catalog-routes'] = middleware['catalog-routes'].default || middleware['catalog-routes']

middleware['check-user-info'] = require('../middleware/check-user-info.js')
middleware['check-user-info'] = middleware['check-user-info'].default || middleware['check-user-info']

middleware['dev-access'] = require('../middleware/dev-access.js')
middleware['dev-access'] = middleware['dev-access'].default || middleware['dev-access']

middleware['isActive'] = require('../middleware/isActive.js')
middleware['isActive'] = middleware['isActive'].default || middleware['isActive']

middleware['manager'] = require('../middleware/manager.js')
middleware['manager'] = middleware['manager'].default || middleware['manager']

middleware['member'] = require('../middleware/member.js')
middleware['member'] = middleware['member'].default || middleware['member']

middleware['processSplashFilters'] = require('../middleware/processSplashFilters.js')
middleware['processSplashFilters'] = middleware['processSplashFilters'].default || middleware['processSplashFilters']

middleware['seo-redirects'] = require('../middleware/seo-redirects.js')
middleware['seo-redirects'] = middleware['seo-redirects'].default || middleware['seo-redirects']

middleware['seoText'] = require('../middleware/seoText.js')
middleware['seoText'] = middleware['seoText'].default || middleware['seoText']

middleware['set-current-city-from-cookies'] = require('../middleware/set-current-city-from-cookies.js')
middleware['set-current-city-from-cookies'] = middleware['set-current-city-from-cookies'].default || middleware['set-current-city-from-cookies']

middleware['trailingSlashRedirect'] = require('../middleware/trailingSlashRedirect.js')
middleware['trailingSlashRedirect'] = middleware['trailingSlashRedirect'].default || middleware['trailingSlashRedirect']

export default middleware
